@use '../common/styles/base';

.layout_main {
  background-color: base.$theme-white;
}

.sider {
  background-color: base.$theme-white !important;
  height: 100vh;
  position: sticky !important;
  top: 0;
  border-right: 1px solid rgb(208, 208, 208);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.menu {
  background-color: base.$theme-white;
}

.menu__button {
  margin-bottom: 16px;
  margin-left: 13px;
  background-color: base.$theme-white !important;
  border: none;
}

.menu__button_opened {
  justify-content: space-between;
}

.outletContainer {
  transition: 0.3s;
  margin-left: 200px;
}
.outletContainer_colapsed {
  transition: 0.3s;
  margin-left: 40px;
}

.info__element {
}

.info__element_title {
  font-size: 0.8rem;
  text-wrap: nowrap;
}

.info__element_data {
  text-wrap: balance;
  font-weight: 700;
  font-size: 1rem;
  line-height: 28px;
}

@use '../../styles/style.module.scss';

.layout__header_exchange {
  @extend .layout__header;
  justify-content: flex-start;
  gap: 10px;
  height: fit-content;
}

.layout__content_OneExchange {
  @extend .layout__content;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.info_section {
  justify-content: space-around;
  gap: 20px;
  overflow: scroll;
}

.info__raw {
  gap: 60px;
}

@media (max-width: 600px) {
  .layout__header_exchange,
  .info_section {
    flex-direction: column;
  }
}

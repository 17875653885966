$theme-white: #f2f5fa;

.layout {
  background-color: #f2f5fa;
  min-height: 100vh;
  margin-left: 10px;
}

.layout__header {
  padding: 24px;
  background: transparent;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
}

.layout__content {
  overflow: 'initial';
  padding: 24px;
  border-radius: 10px;
  background-color: white;
}

.crypto_icon {
  width: 25px;
  height: 25px;
}

.module_header {
  font-weight: bold;
  font-size: 1.8rem;
  text-wrap: nowrap;
}

@media (max-width: 600px) {
  .layout__content {
    padding: 5px;
  }
}
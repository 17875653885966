@use '../../../common/styles/base';

.layout__header_exTool {
  @extend .layout__header;
  justify-content: flex-start;
}

.stats__container {
  width: 500px;
}

.stats__data {
  flex-direction: column;
  gap: 10px;
}

.stats__title {
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 60px;
}

.statsData__raw {
  width: 400px;
  gap: 60px;
}

// todo - есть такие же стили необходимо их объединить или переименовать
.info__element {
  width: 300px;
}

.info__element_title {
  font-size: 0.6rem;
}

.info__element_data {
  font-weight: 700;
  font-size: 0.8rem;
  line-height: 28px;
}

.filterBar__container {
  width: 1157px;
  margin-bottom: 20px;
  padding: 10px 10px 10px 0;
}

.filterBar__content {
  justify-content: space-between;
}

.filterBar_container {
  padding: 10px 0 10px 0;
}

.layout__header_clearing {
  @extend .layout__header;
  gap: 10px;
  justify-content: space-between;
  height: fit-content;
}

.header_content_selectInfo {
  gap: 10px;
  align-items: center;
}

@media (max-width: 600px) {
  .layout__header_clearing,
  .header_content_selectInfo {
    flex-direction: column;
  }
}

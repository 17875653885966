@use '../../../common/styles/base';

// todo sort styles by each folder;

.layout__content_exchanges {
  @extend .layout__content;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.filerBar_container {
}

.info_block_title {
  font-size: large;
  font-weight: 700;
  line-height: 30px;
}

.updateInfo {
  width: 140px;
  height: 30px;
  align-items: center;
  gap: 10px;
}

.instrutionButton {
  width: 10rem;
  font-size: 0.8rem;
}

.layout__header_exchanges {
  @extend .layout__header;
  gap: 10px;
  justify-content: space-between;
  height: fit-content;
}

.header_content {
  align-items: center;
  gap: 10px;
}

.header_content_selectInfo {
  gap: 10px;
  align-items: center;
}

@media (max-width: 600px) {
  .layout__header_exchanges,
  .header_content,
  .header_content_selectInfo {
    flex-direction: column;
  }
  .updateInfo {
    align-self: flex-start;
  }
}
